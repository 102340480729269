// State
export const state = () => ({
	loading: false,
});
// Actions
export const actions = {
	async registration({ commit, dispatch, rootGetters }, data) {
		commit("loading", true)
		const form = JSON.parse(JSON.stringify(data))
		const token = form.recaptchaToken
		delete form.recaptchaToken
		const rememberMe = form.rememberMe
		delete form.rememberMe


		// check referral types
		const referral = this.$cookie.get('referral')
		const previousUrl = this.$cookie.get('previous_url')
		const bannerCookieId = this.$cookie.get('banner_cookie_id')
		const bannerId = this.$cookie.get('banner_uuid') // This cookie is from /visit route
		const bannerUrl = this.$cookie.get('banner_url') // This cookie is from /visit route


		let referralId = null;
		let referralType = null;
		let referralCurrency = '';

		if (referral) {
			const { type, userId, currency } = referral
			referralId = userId
			referralCurrency = currency
			if (type === 'u') referralType = 'player'
			else if (type === 'a') referralType = 'agent'

		}

		// Logic
		// If the registered currency is not matched with the referral currency, then the player will be normal player
		// If they are matched, the player will be referral player under the player who generated the link;
		const isReferralAndRegisteredCurrencyMatched = referralCurrency?.toLowerCase() === data?.currency?.toLowerCase()

		const headers = {
			'Content-Type': 'Application/json',
			'auth-key': token,
		}
		if (referralId && referralType && isReferralAndRegisteredCurrencyMatched === true) {
			headers.Cookies = `referral_id=${referralId}; referral_type=${referralType}`
		}
		if (previousUrl) {
			headers['X-AFFILIATE-URL'] = previousUrl
		}
		if (bannerCookieId) {
			headers['X-BANNER-ID'] = bannerCookieId
		}
		if (bannerId) {
			headers['X-BANNER-ID'] = bannerId
			if (bannerUrl) {
				headers['X-BANNER-URL'] = bannerUrl
			}
		}
		form.language = rootGetters['base-settings/getWhiteLabelLocale']

		const url = `/registration/option/${data.reg_flow}`

		if ([2, 3, 4, 5].includes(data.reg_flow)) {
			delete form.bank_id
			delete form.bank_account_number
			delete form.bank_currency
			delete form.bank_branch

			if ([4, 5].includes(data.reg_flow)) {
				delete form.account_holder_name
			}
		}

		delete form.reg_flow
		delete form.country

		return await this.$axios
			.post(url, form, {
				headers
			})
			.then(async (response) => {
				if (response.status === 201) {
					await this.$cookie.set('token', response.headers.token, {
						path: '/',
						maxAge: 60 * 60 * 3 - 60,
					})
					await this.commit('player/setPlayerToken', response.headers.token)

					await this.$cookie.set('player', response.data)
					this.$cookie.set('currency', response.data.currency)
					await this.commit('player/setPlayer', response.data)
					this.$cookie.set('country_code', response?.data?.country_code)

					await this.dispatch('settings/toggleIsLogin', true, { root: true })
					await this.dispatch('player/totalBalance', true, { root: true })
					if (rememberMe) {
						this.$cookie.set('un', response.data.player_id, {
							path: '/',
							maxAge: 60 * 60 * 24 * 30 - 60,
						})
						this.$cookie.set('pn', response.data.phone, {
							path: '/',
							maxAge: 60 * 60 * 24 * 30 - 60,
						})
					}
					// Success
					commit('loading', false)
					this.$toast.success(this.getters['themes/language-string/tl']('registerSuccessful'))
					if (isReferralAndRegisteredCurrencyMatched === true && referralId) {
						await this.$cookie.remove('referral')
					}
					await this.$cookie.remove('previous_url')
					await this.$cookie.remove('banner_cookie_id')
					await this.$cookie.remove('banner_uuid')
					await this.$cookie.remove('banner_url')
					if (this.$cookie.get('banner_id')) {
						this.$cookie.remove('banner_id')
					}
					dispatch('themes/player-bank/getBankPlayerAccount', true, { root: true })
					// this.$router.push('/player/deposits/apply')
					return true;
				}
			})
			.catch((e) => {
				commit('loading', false)
			})
	},
	// login
	async login({ commit, dispatch }, form) {
		commit('loading', true)
		const token = form.recaptchaToken
		delete form.recaptchaToken
		const rememberMe = form.rememberMe
		delete form.rememberMe
		await this.$axios
			.post(`/players/login`, form, {
				headers: {
					'Content-Type': 'Application/json',
					'auth-key': token
				}
			})
			.then(async (response) => {
				if (response.status === 200) {
					this.$cookie.set('token', response.headers.token, {
						path: '/',
						maxAge: 60 * 60 * 3 - 60,
					})
					dispatch('base-settings/updateLocaleAndSetCookie', response.data.language, { root: true })
					this.commit('player/setPlayerToken', response.headers.token)
					this.$cookie.set('country_code', response?.data?.country_code)

					this.$cookie.set('player', response.data)
					this.$cookie.set('currency', response.data.currency)
					await this.commit('player/setPlayer', response.data)
					this.commit('player/setTotalBalance', response?.data?.balance)

					// Success
					commit('loading', false)
					this.$toast.success(this.getters['themes/language-string/tl']('loginSuccessful'))

					if (form.player_id) {
						if (rememberMe) this.$cookie.set('un', form.player_id, {
							path: '/',
							maxAge: 60 * 60 * 24 * 30 - 60,
						})
						else this.$cookie.remove('un')
					} else if (form.phone) {
						if (rememberMe) this.$cookie.set('pn', form.phone, {
							path: '/',
							maxAge: 60 * 60 * 24 * 30 - 60,
						})
						else this.$cookie.remove('pn')
					}

					this.$router.go(('/'))
				}
			})
			.catch((e) => {
				commit('loading', false)
			})
	},
}
// Mutations
export const mutations = {
	loading: (state, v) => {
		state.loading = v
	},
}