export const state = () => ({
	configuration: {
		wl_locale: null
	},
	validation: {
		wl_locale: /^[a-z]{2}-[A-Z]{2}$/
	}
})

export const actions = {
	updateLocaleAndSetCookie({ dispatch, commit }, locale) {
		this.$cookie.set('wl_locale', locale)
		commit('setConfiguration', { wl_locale: locale })
		dispatch('settings/locale', locale, { root: true })
	}
}

export const mutations = {
	setConfiguration(state, data) {
		state.configuration = {
			...state.configuration,
			...data
		}
	}
}

export const getters = {
	getWhiteLabelLocale: (state) => {
		return state.configuration.wl_locale
	}
}
